import { useCurrentUser } from '@core/hooks/CurrentUserHooks'
import { useAppRouter } from '@core/hooks/UseAppRouter'
import { X_AUTH_TOKEN } from '@core/utilities/Client'
import { getCookie } from '@core/utilities/Cookies'
import React from 'react'
import { useEffect } from 'react'
const createPageUtils = ({ loginPage, getPageIndex }) => {
  return {
    anonymousAccess: (Page) => {
      return (props) => {
        const [user] = useCurrentUser()
        const router = useAppRouter()
        useEffect(() => {
          if (user?.profile) {
            router.push(getPageIndex())
          }
        }, [user?.profile])
        return <Page {...props} />
      }
    },
    privateAcess: (Page) => {
      return (props) => {
        const router = useAppRouter()
        useEffect(() => {
          if (!getCookie(X_AUTH_TOKEN)) {
            router.push(loginPage)
          }
        }, [])
        return <Page {...props} />
      }
    }
  }
}
export const { anonymousAccess, privateAcess } = createPageUtils({
  loginPage: '/login',
  getPageIndex: () => `/review-jobs`
})
